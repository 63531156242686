<template>
  <b-card title="Cheques">
    <cheque-filter @filtersUpdated="filtersChangeHandler" />
    <cheque-list :filters="filters" />
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import ChequeList from '../../components/Cheques/ChequeList.vue'
import ChequeFilter from '../../components/Cheques/ChequeFilter.vue'

export default {
  components: {
    BCard,
    ChequeList,
    ChequeFilter,
  },
  data() {
    return {
      filters: {},
    }
  },
  methods: {
    filtersChangeHandler(filterData) {
      this.filters = filterData
    },
  },
}

</script>
