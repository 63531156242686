<template>
  <div>
    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        label="Small Spinner"
        variant="primary"
      />
    </div>

    <b-alert
      variant="danger"
      :show="loadingError ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ loadingError }}
        </p>
      </div>
    </b-alert>

    <add-cheque
      v-if="showChequeForm"
      @modal-closed="showChequeForm = false"
      @cheque-added="addChequeHandler"
    />

    <b-row>
      <b-col
        align="right"
      >
        <b-button
          variant="outline-primary"
          class="mb-1 ml-2"
          @click="showChequeForm = true"
        >
          Add Cheque
        </b-button>

      </b-col>
    </b-row>

    <b-table
      v-if="!loading && !loadingError"
      :items="results"
      :fields="tableFields"
      responsive="sm"
      striped
      hover
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      class="compact-table"
      no-local-sorting
      @sort-changed="sortingChanged"
    >
      <template #cell(cheque_date)="data">
        {{ formatedDate(data.item.cheque_date) }}
      </template>
      <template #cell(received_on)="data">
        {{ formatedDate(data.item.received_on) }}
      </template>
      <template #cell(amount)="data">
        {{ formateNumber(data.item.amount) }}
      </template>
    </b-table>

    <detailed-pagination
      v-if="!loading && !loadingError"
      :total-items="totalItems"
      :per-page="perPage"
      :current-page="currentPage"
      @page-changed="(page) => {currentPage = page}"
    />

  </div>

</template>

<script>
import {
  BSpinner, BTable, BAlert, BRow, BCol, BButton, VBTooltip,
} from 'bootstrap-vue'
import axios from 'axios'

import dateMixin from '@/mixins/dateMixin'
import formatMixin from '@/mixins/formatMixin'
import AddCheque from './AddCheque.vue'
import DetailedPagination from '../UI/DetailedPagination.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BSpinner,
    BTable,
    BRow,
    BCol,
    BButton,
    BAlert,
    DetailedPagination,
    AddCheque,
  },
  mixins: [dateMixin, formatMixin],
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      loadingError: null,

      results: [],
      tableFields: [
        { key: 'cheque_date', label: 'Cheque Date', sortable: true },
        { key: 'cheque_number', label: 'Cheque Number', sortable: true },
        {
          key: 'amount', label: 'Amount', sortable: true, class: 'text-right pr-2',
        },
        { key: 'received_on', label: 'Received On', sortable: true },
        { key: 'party__identifier', label: 'Party', sortable: true },
        { key: 'bank__name', label: 'Bank', sortable: true },

      ],
      currentPage: 1,
      perPage: 50,
      totalItems: 0,
      sortBy: 'cheque_date',
      sortDesc: true,
      submitting: false,
      showChequeForm: false,
    }
  },
  watch: {
    currentPage() {
      this.fetchItems()
    },
    filters() {
      if (this.currentPage !== 1) {
        this.currentPage = 1
      } else {
        this.fetchItems()
      }
    },
  },
  created() {
    this.fetchItems()
  },
  methods: {
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy
      this.sortDesc = ctx.sortDesc
      this.fetchItems()
    },

    fetchItems() {
      this.loading = true
      axios.post('/accounts/filtered_cheques', {
        page: this.currentPage,
        per_page: this.perPage,
        sort_by: this.sortBy,
        sort_desc: this.sortDesc,
        filters: { ...this.filters },
      })
        .then(res => {
          this.results = res.data.results
          this.totalItems = res.data.count
          this.loadingError = null
          this.loading = false
        }).catch(error => {
          this.loadingError = error?.response?.data?.error || 'Something went wrong'
          this.loading = false
        })
    },
    addChequeHandler() {
      this.showChequeForm = false
      this.fetchItems()
    },
  },
}
</script>
