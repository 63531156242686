<template>
  <b-modal
    v-model="showModal"
    title="Add Cheque"
    size="lg"
    class="m-4"
    no-close-on-backdrop
    @hidden="$emit('modal-closed')"
    @ok="submitHandler"
  >
    <b-form
      @submit.prevent="submitHandler"
    >
      <b-row>
        <b-col cols="12">
          <b-alert
            variant="danger"
            :show="errorMessage ? true : false"
          >
            <div
              v-if="typeof errorMessage === 'string'"
              class="alert-body"
            >
              <p>
                {{ errorMessage }}
              </p>
            </div>
            <div
              v-else-if="errorMessage.detail"
              class="alert-body"
            >
              <p>
                {{ errorMessage.detail }}
              </p>
            </div>
            <div
              v-else-if="errorMessage.non_field_errors"
              class="alert-body"
            >
              <p>
                {{ errorMessage.non_field_errors[0] }}
              </p>
            </div>
            <div
              v-else-if="errorMessage.message"
              class="alert-body"
            >
              <p>
                {{ errorMessage.message }}
              </p>
            </div>

          </b-alert>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Party"
          >
            <custom-v-select
              id="party"
              v-model="selectedParty"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="partyOptions"
              label="text"
            />
            <p
              v-if="errorMessage && errorMessage.party"
              class="error-message"
            >
              {{ errorMessage.party[0] }}
            </p>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Bank"
            label-for="Bank"
          >
            <custom-v-select
              id="bank"
              v-model="selectedBank"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="bankOptions"
              label="text"
            />
            <p
              v-if="errorMessage && errorMessage.bank"
              class="error-message"
            >
              {{ errorMessage.bank[0] }}
            </p>
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group
            label="Cheque Number"
            label-for="chequeNumber"
          >
            <b-form-input
              id="chequeNumber"
              v-model="chequeNumber"
              type="text"
              placeholder="Cheque Number"
            />
            <p
              v-if="errorMessage && errorMessage.cheque_number"
              class="error-message"
            >
              {{ errorMessage.cheque_number[0] }}
            </p>
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <label for="cheque-date">Cheque Date</label>
          <b-form-datepicker
            id="cheque-date"
            v-model="chequeDate"
            menu-class="w-100"
            calendar-width="100%"
          />
          <p
            v-if="errorMessage && errorMessage.cheque_date"
            class="error-message"
          >
            {{ errorMessage.cheque_date[0] }}
          </p>
        </b-col>

        <b-col cols="6">
          <b-form-group
            label="Cheque Amount"
            label-for="amount"
          >
            <b-form-input
              id="amount"
              v-model="chequeAmount"
              type="number"
              placeholder="Cheque Amount"
            />
            <p
              v-if="errorMessage && errorMessage.amount"
              class="error-message"
            >
              {{ errorMessage.amount[0] }}
            </p>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <label for="received-on">Received On</label>
          <b-form-datepicker
            id="received-on"
            v-model="receivedOn"
            menu-class="w-100"
            calendar-width="100%"
          />
          <p
            v-if="errorMessage && errorMessage.received_on"
            class="error-message"
          >
            {{ errorMessage.received_on[0] }}
          </p>
        </b-col>

      </b-row>

    </b-form>
    <template #modal-footer="{ ok, cancel }">
      <b-button
        variant="secondary"
        @click="cancel()"
      >
        Cancel
      </b-button>
      <b-button
        variant="primary"
        :disabled="saving"
        @click="ok()"
      >
        Create
        <b-spinner
          v-if="saving"
          small
          label="Small Spinner"
        />
      </b-button>

    </template>
  </b-modal>

</template>

<script>
import {
  BModal, BRow, BCol, BFormGroup, BButton, BFormInput, BFormDatepicker, BForm, BSpinner, BAlert,
} from 'bootstrap-vue'
import CustomVSelect from '@/components/UI/CustomVSelect.vue'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    CustomVSelect,
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormDatepicker,
    BButton,
    BSpinner,
    BAlert,
  },

  data() {
    return {
      showModal: true,
      selectedParty: null,
      partyOptions: [],
      selectedBank: null,
      bankOptions: [],
      chequeNumber: null,
      chequeAmount: null,
      receivedOn: null,
      chequeDate: null,
      saving: false,
      errorMessage: {},
    }
  },
  created() {
    this.fetchOptions()
  },
  methods: {
    fetchOptions() {
      axios.get('/accounts/banks_options')
        .then(res => {
          this.bankOptions = res.data.data
        })

      axios.get('/accounts/buyers_options')
        .then(res => {
          this.partyOptions = res.data.data
        })
    },
    submitHandler(event) {
      event.preventDefault()
      const formData = new FormData()
      if (this.selectedParty) {
        formData.append('party', this.selectedParty.id)
      }
      if (this.selectedBank) {
        formData.append('bank', this.selectedBank.id)
      }
      if (this.chequeNumber) {
        formData.append('cheque_number', this.chequeNumber)
      }
      if (this.chequeDate) {
        formData.append('cheque_date', this.chequeDate)
      }
      if (this.chequeAmount) {
        formData.append('amount', this.chequeAmount)
      }
      if (this.receivedOn) {
        formData.append('received_on', this.receivedOn)
      }

      this.saving = true
      axios.post('/accounts/cheque/', formData)
        .then(res => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message || 'Cheque Created',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })

          this.$emit('cheque-added')
          this.saving = false
        }).catch(error => {
          this.errorMessage = error?.response?.data || 'Something went wrong'
          this.saving = false
        })
    },
  },
}

</script>

<style scoped>
.error-message {
  color: red
}
</style>
