<template>
  <div>
    <b-row>
      <b-col md="3">
        <b-form-group
          label="Bank"
        >
          <custom-v-select
            id="bank"
            v-model="selectedBanks"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="bankOptions"
            label="text"
            placeholder="Select Bank"
            multiple
            :close-on-select="false"
            :deselect-from-dropdown="true"
            @input="filtersChangeHandler"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          label="Party"
        >
          <custom-v-select
            id="party"
            v-model="selectedParties"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="partyOptions"
            label="text"
            placeholder="Select Party"
            multiple
            :close-on-select="false"
            :deselect-from-dropdown="true"
            @input="filtersChangeHandler"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          label="Area"
          label-for="area"
        >
          <custom-v-select
            id="area"
            v-model="selectedAreas"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="areaOptions"
            label="text"
            placeholder="Select Area"
            multiple
            :close-on-select="false"
            :deselect-from-dropdown="true"
            @input="filtersChangeHandler"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          label="Salesman"
          label-for="salesman"
        >
          <custom-v-select
            id="salesman"
            v-model="selectedSalesmans"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="salesmanOptions"
            label="text"
            placeholder="Select Salesman"
            multiple
            :close-on-select="false"
            :deselect-from-dropdown="true"
            @input="filtersChangeHandler"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          label="Cheque Date Range"
        >
          <flat-picker
            v-model="chequeDateRange"
            placeholder="Select Date Range"
            :config="{ mode: 'range'}"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          label="Received On Date Range"
        >
          <flat-picker
            v-model="receivedDateRange"
            placeholder="Select Date Range"
            :config="{ mode: 'range'}"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          label="Cheque Number"
        >
          <b-form-input
            v-model="chequeNumber"
            type="text"
            placeholder="Search Cheque Number"
            @input="filtersChangeHandler"
          />
        </b-form-group>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  VBTooltip, BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import axios from 'axios'
import CustomVSelect from '@/components/UI/CustomVSelect.vue'
import FlatPicker from '@/components/UI/FlatPicker.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    CustomVSelect,
    FlatPicker,
  },
  data() {
    return {
      bankOptions: [],
      partyOptions: [],
      selectedBanks: [],
      selectedParties: [],
      areaOptions: [],
      selectedAreas: [],
      salesmanOptions: [],
      selectedSalesmans: [],
      receivedDateRange: null,
      chequeDateRange: null,
      chequeNumber: null,
    }
  },
  watch: {
    receivedDateRange(newValue) {
      if (newValue) {
        const [fromDate, toDate] = newValue.split(' to ')
        if (fromDate && toDate) {
          this.filtersChangeHandler()
        }
      } else if (newValue === '') {
        this.filtersChangeHandler()
      }
    },
    chequeDateRange(newValue) {
      if (newValue) {
        const [fromDate, toDate] = newValue.split(' to ')
        if (fromDate && toDate) {
          this.filtersChangeHandler()
        }
      } else if (newValue === '') {
        this.filtersChangeHandler()
      }
    },
  },
  created() {
    this.fetchOptions()
  },
  methods: {
    fetchOptions() {
      axios.get('/accounts/banks_options')
        .then(res => {
          this.bankOptions = res.data.data
        })

      axios.get('/accounts/buyers_options')
        .then(res => {
          this.partyOptions = res.data.data
        })

      axios.get('/accounts/areas_options')
        .then(res => {
          this.areaOptions = res.data.data
        })

      axios.get('/accounts/salesmans_options')
        .then(res => {
          this.salesmanOptions = res.data.data
        })
    },
    filtersChangeHandler() {
      const filtersdata = { }
      if (this.selectedBanks.length > 0) {
        filtersdata.banks = this.selectedBanks.map(bank => bank.id)
      }
      if (this.selectedParties.length > 0) {
        filtersdata.parties = this.selectedParties.map(party => party.id)
      }
      if (this.selectedAreas.length > 0) {
        filtersdata.areas = this.selectedAreas.map(area => area.id)
      }
      if (this.selectedSalesmans.length > 0) {
        filtersdata.salesmans = this.selectedSalesmans.map(salesman => salesman.id)
      }
      if (this.receivedDateRange) {
        const [receivedDateFrom, receivedDateTo] = this.receivedDateRange.split(' to ')
        if (receivedDateFrom && receivedDateTo) {
          filtersdata.received_date_from = receivedDateFrom
          filtersdata.received_date_to = receivedDateTo
        }
      }
      if (this.chequeDateRange) {
        const [chequeDateFrom, chequeDateTo] = this.chequeDateRange.split(' to ')
        if (chequeDateFrom && chequeDateTo) {
          filtersdata.cheque_date_from = chequeDateFrom
          filtersdata.cheque_date_to = chequeDateTo
        }
      }
      if (this.chequeNumber) {
        filtersdata.cheque_number = this.chequeNumber
      }
      this.$emit('filtersUpdated', filtersdata)
    },
  },
}
</script>
